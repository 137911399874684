import { CreateDisbursementReason, DisbursementReason } from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";
import { WithCreate } from "./mixins/WithCreate";

class Endpoint extends BaseEndpoint("disbursement-reasons") {
  getAll(query?: { label?: string }): Promise<DisbursementReason[]> {
    return this.request("", { query });
  }
}

export const DisbursementReasonEndpoint = WithCreate<
  typeof Endpoint,
  DisbursementReason,
  CreateDisbursementReason
>(Endpoint);
