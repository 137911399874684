import { Type } from "../../domain";
import { EndpointType } from "../BaseEndpoint";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export interface WithDeleteType {
  delete(id: string): Promise<void>;
}

export const WithDelete = <EndpointT extends EndpointType>(
  clazz: EndpointT,
): Type<WithDeleteType> & EndpointT =>
  class extends clazz {
    /**
     * Delete an existing resource
     *
     * @param id ID of the resource to delete
     */
    delete(id: string): Promise<void> {
      return this.request(id, {
        method: "DELETE",
      });
    }
  };
