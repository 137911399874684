import {
  BillPage,
  ContractPolicyPage,
  CreateOrganization,
  Organization,
  OrganizationPage,
  Outgoing,
  UpdateOrganization,
} from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";
import { WithCreate } from "./mixins/WithCreate";
import { WithSearchableGetPage } from "./mixins/WithSearchableGetPage";
import { WithUpdate } from "./mixins/WithUpdate";
import { SearchQuery } from "./query";
import { GetBillsQuery } from "./query/GetBillsQuery";
import { GetOrganizationsQuery } from "./query/GetOrganizationsQuery";

export class Endpoint extends BaseEndpoint("organizations") {
  getOrganizationOutgoing(organizationId: string): Promise<Outgoing> {
    return this.request(`${organizationId}/outgoing`);
  }

  getOrganizationBills(
    organizationId: string,
    query?: GetBillsQuery,
  ): Promise<BillPage> {
    return this.request(`${organizationId}/bills`, {
      query,
    });
  }

  getOrganizationContractPolicies(
    organizationId: string,
    query?: SearchQuery,
  ): Promise<ContractPolicyPage> {
    return this.request(`${organizationId}/contract-policies`, {
      query,
    });
  }

  getAll(): Promise<Organization[]> {
    return this.request("all");
  }
}

const withSearch = WithSearchableGetPage<
  typeof Endpoint,
  Organization,
  OrganizationPage,
  GetOrganizationsQuery
>(Endpoint);

const withCreate = WithCreate<
  typeof withSearch,
  Organization,
  CreateOrganization
>(withSearch);

const withUpdate = WithUpdate<
  typeof withCreate,
  Organization,
  UpdateOrganization
>(withCreate);

export const OrganizationEndpoint = withUpdate;
