import { CreateToken, Jwt } from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";

export class TokensEndpoint extends BaseEndpoint("tokens") {
  create(body: CreateToken = {}): Promise<Jwt> {
    return this.request("", {
      method: "POST",
      body,
    });
  }
}
