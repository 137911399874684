import { AuthProvider, Requester, RequesterResponse } from "./domain";
import {
  AdministrationRoutesEndpoint,
  AtcEndpoint,
  AuditEndpoint,
  BankEndpoint,
  BillEndpoint,
  BrandEndpoint,
  CashRegisterEndpoint,
  CategoryEndpoint,
  ClaimEndpoint,
  ClientEndpoint,
  CompanyEndpoint,
  CompanyTypeEndpoint,
  ContractEndpoint,
  ContractPolicyEndpoint,
  CountryEndpoint,
  DeliveryEndpoint,
  DeliveryImportEndpoint,
  DisbursementEndpoint,
  DisbursementReasonEndpoint,
  FormEndpoint,
  InnEndpoint,
  InventoryEndpoint,
  MovementEndpoint,
  OrderEndpoint,
  OrderPreparationEndpoint,
  OrderRecordEndpoint,
  OrganizationEndpoint,
  PackagingEndpoint,
  PersonEndpoint,
  PharmacyEndpoint,
  PharmacyProductEndpoint,
  ProductEndpoint,
  ProductViewEndpoint,
  ReportEndpoint,
  RoleEndpoint,
  SaleEndpoint,
  SectionEndpoint,
  SupplierEndpoint,
  TokensEndpoint,
  UnitEndpoint,
  UsageEndpoint,
  UserEndpoint,
  VatRateEndpoint,
  WholesalerClaimsEndpoint,
  WholesalerEndpoint,
  WholesalerProductEndpoint,
} from "./endpoints";
import { EMECeFEndpoint } from "./endpoints/EMECeFEndpoint";
import { ProductBatchEndpoint } from "./endpoints/ProductEndpoint";
import { RewardProgramEndpoint } from "./endpoints/RewardProgramEndpoint";
import { StockExportEndpoint } from "./endpoints/StockExportEndpoint";

export interface ClientOptions {
  requester?: Requester;
  authProvider?: AuthProvider;
  log?: (...message: string[]) => void;
}

export class ApiClient {
  readonly administrationRoutes = new AdministrationRoutesEndpoint(this);
  readonly atcs = new AtcEndpoint(this);
  readonly banks = new BankEndpoint(this);
  readonly bills = new BillEndpoint(this);
  readonly brands = new BrandEndpoint(this);
  readonly cashRegisters = new CashRegisterEndpoint(this);
  readonly movements = new MovementEndpoint(this);
  readonly categories = new CategoryEndpoint(this);
  readonly claims = new ClaimEndpoint(this);
  readonly clients = new ClientEndpoint(this);
  readonly companies = new CompanyEndpoint(this);
  readonly companyTypes = new CompanyTypeEndpoint(this);
  readonly contracts = new ContractEndpoint(this);
  readonly contractPolicies = new ContractPolicyEndpoint(this);
  readonly countries = new CountryEndpoint(this);
  readonly disbursementReasons = new DisbursementReasonEndpoint(this);
  readonly disbursement = new DisbursementEndpoint(this);
  readonly deliveries = new DeliveryEndpoint(this);
  readonly deliveryImports = new DeliveryImportEndpoint(this);
  readonly forms = new FormEndpoint(this);
  readonly inns = new InnEndpoint(this);
  readonly inventories = new InventoryEndpoint(this);
  readonly organizations = new OrganizationEndpoint(this);
  readonly packagings = new PackagingEndpoint(this);
  readonly persons = new PersonEndpoint(this);
  readonly pharmacies = new PharmacyEndpoint(this);
  readonly pharmacyProducts = new PharmacyProductEndpoint(this);
  readonly products = new ProductEndpoint(this);
  readonly productBatches = new ProductBatchEndpoint(this);
  readonly productViews = new ProductViewEndpoint(this);
  readonly reports = new ReportEndpoint(this);
  readonly roles = new RoleEndpoint(this);
  readonly rewardPrograms = new RewardProgramEndpoint(this);
  readonly sales = new SaleEndpoint(this);
  readonly sections = new SectionEndpoint(this);
  readonly stockExports = new StockExportEndpoint(this);
  readonly suppliers = new SupplierEndpoint(this);
  readonly tokens = new TokensEndpoint(this);
  readonly units = new UnitEndpoint(this);
  readonly usage = new UsageEndpoint(this);
  readonly users = new UserEndpoint(this);
  readonly vatRates = new VatRateEndpoint(this);
  readonly wholesalers = new WholesalerEndpoint(this);
  readonly wholesalerProducts = new WholesalerProductEndpoint(this);
  readonly orders = new OrderEndpoint(this);
  readonly orderRecords = new OrderRecordEndpoint(this);
  readonly orderPreparation = new OrderPreparationEndpoint(this);
  readonly audit = new AuditEndpoint(this);
  readonly eMecef = new EMECeFEndpoint(this);
  readonly wholesalerClaims = new WholesalerClaimsEndpoint(this);

  readonly requester: Requester;

  constructor(
    readonly apiUrl: string,
    { requester = fetch, authProvider, log }: ClientOptions = {},
  ) {
    this.requester = async (url, init = {}) => {
      if (authProvider != null) {
        const token = await authProvider(url, init);
        if (token != null) {
          const withPrefix = `Bearer ${token}`;
          if (init.headers == null) {
            init.headers = { ["Authorization"]: withPrefix };
          } else {
            init.headers["Authorization"] = withPrefix;
          }
        }
      }
      log?.(`[${init.method ?? "GET"}] ${url}`);
      return requester(url, init).then((res): RequesterResponse => {
        // no content
        if (res.status === 204) {
          return {
            status: res.status,
            json: () => Promise.resolve(""),
            blob: () => res.blob(),
          };
        }
        return {
          status: res.status,
          json: () => res.json(),
          blob: () => res.blob(),
        };
      });
    };
  }
}
