import { ComponentStyleConfig } from "@chakra-ui/react";

const badge: ComponentStyleConfig = {
  baseStyle: (properties) => ({
    bg: `${properties.colorScheme}.100`,
    borderRadius: "6px",
    color: `${properties.colorScheme}.800`,
    colorScheme: properties.colorScheme ?? "green",
    px: "8px",
    py: "2px",
  }),
  variants: {
    active: () => ({
      bg: `green.100`,
      color: `green.800`,
    }),
    inactive: () => ({
      bg: `gray.100`,
      color: `gray.700`,
    }),
  },
};

export default badge;
