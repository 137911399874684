import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "@/store";

export interface BillingSliceState {
  printDetails: boolean;
}

const initialState: BillingSliceState = {
  printDetails: false,
};

export const billingSlice = createSlice({
  initialState,
  name: "billing",
  reducers: {
    setPrintDetails: (state, { payload }: PayloadAction<boolean>) => {
      state.printDetails = payload;
    },
  },
});

export default billingSlice.reducer;

export const { setPrintDetails } = billingSlice.actions;

export const selectPrintDetails = (state: RootState): boolean =>
  state.billing.printDetails;
