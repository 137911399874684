import {
  CreateSale,
  Sale,
  SalePage,
  SearchGetManySalesQueryParams,
  UpdateSale,
} from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";
import { WithEdit } from "./mixins/WithEdit";
import { WithGet } from "./mixins/WithGet";
import { WithSearchableGetPage } from "./mixins/WithSearchableGetPage";

export class Endpoint extends BaseEndpoint("sales") {
  getHistory(id: string): Promise<Sale[]> {
    return this.request(`${id}/history`);
  }
}

const withGetOne = WithGet<typeof Endpoint, Sale>(Endpoint);

const withGetAll = WithSearchableGetPage<
  typeof withGetOne,
  Sale,
  SalePage,
  SearchGetManySalesQueryParams
>(withGetOne);

export const SaleEndpoint = WithEdit<
  typeof withGetAll,
  Sale,
  CreateSale,
  UpdateSale
>(withGetAll);
