import { ApiClient } from "../ApiClient";
import { RequestOptions, Type } from "../domain";

// eslint-disable-next-line @typescript-eslint/ban-types
export type Query = object;

export interface EndpointRequestOptions extends Omit<RequestOptions, "body"> {
  query?: Query;
  body?: unknown;
  type?: "json" | "blob";
}

export type EndpointType = Type<{
  request<T>(subPath?: string, options?: EndpointRequestOptions): Promise<T>;
}>;

type Pojo =
  | string
  | number
  | boolean
  | Date
  | null
  | Pojo[]
  | { [key: string]: Pojo };

export const setQueryMember = (
  key: string,
  value: Pojo,
  params: URLSearchParams,
): void => {
  if (value == null) {
    return;
  }
  if (Array.isArray(value)) {
    for (const nested of value) {
      setQueryMember(`${key}[]`, nested, params);
    }
  } else if (value instanceof Date) {
    params.append(key, value.toISOString());
  } else if (typeof value === "object") {
    for (const [nestedKey, nestedValue] of Object.entries(value)) {
      setQueryMember(`${key}[${nestedKey}]`, nestedValue, params);
    }
  } else if (typeof value === "boolean") {
    params.append(key, value ? "true" : "false");
  } else {
    params.append(key, value.toString());
  }
};

export const setQuery = (
  params: URLSearchParams,
  query: Query,
): URLSearchParams => {
  for (const [key, value] of Object.entries(query)) {
    setQueryMember(key, value, params);
  }
  return params;
};

export interface BaseEndpointType {
  readonly client: ApiClient;

  resolvePath(subPath: string): string;

  request<T>(subPath?: string, options?: EndpointRequestOptions): Promise<T>;
}

export const BaseEndpoint = (path: string): Type<BaseEndpointType> =>
  class {
    constructor(readonly client: ApiClient) {}

    resolvePath(subPath: string) {
      return `${path}/${subPath}`;
    }

    async request<T>(
      subPath = "",
      { query, body, type, ...options }: EndpointRequestOptions = {},
    ): Promise<T> {
      const resolved = this.resolvePath(subPath);
      const url = new URL(resolved, this.client.apiUrl);
      if (query != null) {
        setQuery(url.searchParams, query);
      }
      if (options.headers == null) {
        options.headers = {};
      }
      let serializedBody: string | FormData | undefined;
      if (body != null) {
        if (body instanceof FormData) {
          serializedBody = body;
        } else {
          options.headers["Content-Type"] = "application/json";
          serializedBody = JSON.stringify(body);
        }
      }
      const response = await this.client.requester(url.href, {
        ...options,
        body: serializedBody,
      });

      if (response.status >= 400) {
        const { message } = await response.json();
        throw new Error(message);
      } else if (response.status === 204) {
        return undefined as unknown as T;
      } else {
        if (!type || type === "json") return response.json();
        else return response.blob() as unknown as T;
      }
    }
  };
