import { EndpointType } from "../BaseEndpoint";
import { Page } from "../domain";
import { SearchQuery } from "../query";
import { WithGet } from "./WithGet";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const WithSearchableGetPage = <
  EndpointT extends EndpointType,
  T,
  PageT = Page<T>,
  QueryT extends SearchQuery = SearchQuery,
>(
  clazz: EndpointT,
) => {
  class Base extends clazz {
    /**
     * Get a page of resources
     *
     * @param query the pageable query used to fetch
     */
    getPage(query?: QueryT): Promise<PageT> {
      return this.request("", {
        query,
      });
    }
  }

  return WithGet<typeof Base, T>(Base);
};
