import { useEffect, useState } from "react";
import { useFirebase } from "react-redux-firebase";

import { Center, Loader } from "@meditect/medibase-components";

import { UserContext } from "./UserContext";

export interface UserProviderProperties {
  children?: React.ReactNode;
}

export const UserProvider = ({
  children,
}: UserProviderProperties): React.ReactElement => {
  const { auth } = useFirebase();
  const [user, setUser] = useState(auth().currentUser);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(
    () =>
      auth().onAuthStateChanged((user) => {
        setUser(user);
        setIsLoading(false);
      }),
    [auth],
  );

  return (
    <UserContext.Provider
      value={{
        user,
      }}
    >
      {isLoading && (
        <Center h="100%">
          <Loader />
        </Center>
      )}
      {!isLoading && children}
    </UserContext.Provider>
  );
};
