import { EndpointType } from "../BaseEndpoint";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const WithGet = <EndpointT extends EndpointType, T>(clazz: EndpointT) =>
  class extends clazz {
    /**
     * Get a single resource by id
     *
     * @param id The id of the resource to fetch
     */
    get(id: string): Promise<T> {
      return this.request(id);
    }
  };
