export enum PaymentType {
  CASH = "CASH",
  CHEQUE = "CHEQUE",
  INSURANCE = "INSURANCE",
  CREDIT_CARD = "CREDIT_CARD",
  MOBILE_MONEY = "MOBILE_MONEY",
  CREDIT = "CREDIT",
  CREDIT_ORG = "CREDIT_ORG",
  DISCOUNT = "DISCOUNT",
}
