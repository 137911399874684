import {
  AdministrationRoute,
  CreateOrUpdateAdministrationRoute,
} from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";
import { WithEdit } from "./mixins/WithEdit";
import { WithSearchableGetPage } from "./mixins/WithSearchableGetPage";

export class Endpoint extends BaseEndpoint("administration-routes") {}

const withGetAll = WithSearchableGetPage<typeof Endpoint, AdministrationRoute>(
  Endpoint,
);

export const AdministrationRoutesEndpoint = WithEdit<
  typeof withGetAll,
  CreateOrUpdateAdministrationRoute
>(withGetAll);
