import { intervalToDuration } from "date-fns";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import durationToSeconds from "@/utils/durationToSeconds";

import { isConnected } from "../utils";

export const DATE_WHEN_OFFLINE_KEY = "DATE_WHEN_OFFLINE";

type ConnectivityState = {
  isOnline: boolean;
  secondsSinceOffline: React.MutableRefObject<number>;
};

const ConnectivityContext = createContext<ConnectivityState>({
  isOnline: true,
  secondsSinceOffline: { current: 0 },
});

export function ConnectivityContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [isOnline, setOnline] = useState(true);
  const secondsSinceOffline = useRef(0);
  const [timer, setTimer] = useState<NodeJS.Timeout | undefined>();

  const onOnline = useCallback(async () => {
    setOnline(true);
    window.clearInterval(timer);
    setTimer(undefined);
    secondsSinceOffline.current = 0;
    localStorage.removeItem(DATE_WHEN_OFFLINE_KEY);
  }, [timer]);

  const onOffline = useCallback(async () => {
    setOnline(false);
    // Retrieve existing offline date, for page refreshed when offline
    let offlineDate = localStorage.getItem(DATE_WHEN_OFFLINE_KEY);
    if (!offlineDate) {
      localStorage.setItem(DATE_WHEN_OFFLINE_KEY, new Date().toISOString());
      offlineDate = new Date().toISOString();
    }
    const offlineTime = durationToSeconds(
      intervalToDuration({ end: new Date(), start: new Date(offlineDate) }),
    );
    secondsSinceOffline.current = offlineTime;

    // start interval
    if (!timer) {
      const interval = setInterval(() => {
        secondsSinceOffline.current += 1;
      }, 1000);
      setTimer(interval);
    }
  }, [timer]);

  const onConnectionChange = useCallback(async () => {
    if (await isConnected()) {
      return onOnline();
    }
    return onOffline();
  }, [onOffline, onOnline]);

  useEffect(() => {
    if (localStorage.getItem(DATE_WHEN_OFFLINE_KEY)) {
      onConnectionChange();
    }

    window.addEventListener("online", onConnectionChange);
    window.addEventListener("offline", onConnectionChange);

    return () => {
      window.removeEventListener("online", onConnectionChange);
      window.removeEventListener("offline", onConnectionChange);
      window.clearInterval(timer);
    };
  }, [onConnectionChange, timer]);

  return (
    <ConnectivityContext.Provider value={{ isOnline, secondsSinceOffline }}>
      {children}
    </ConnectivityContext.Provider>
  );
}

export function useConnectivity() {
  return useContext(ConnectivityContext);
}
