import {
  Badge,
  Button,
  Flex,
  forwardRef,
  HStack,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Spinner,
  StackProps,
} from "@chakra-ui/react";
import { PropsWithChildren, useRef } from "react";

import { useIsMobile } from "../../hooks";
import { SearchIcon } from "../Icons/Icons";

export interface SearchbarOptions {
  inputAddons?: React.ReactNode;
  isLoading?: boolean;
  onQueryChange?: (query: string) => void;
  onSearchButtonClick?: () => void;
  query?: string;
  resultsCount?: number;
}

export interface SearchbarProperties
  extends SearchbarOptions,
    Pick<
      InputProps,
      "autoFocus" | "defaultValue" | "isDisabled" | "placeholder"
    >,
    StackProps {}

export const Searchbar = forwardRef<
  PropsWithChildren<SearchbarProperties>,
  "div"
>(
  (
    {
      autoFocus = false,
      children,
      defaultValue,
      inputAddons,
      isDisabled = false,
      isLoading,
      onQueryChange,
      onSearchButtonClick,
      placeholder,
      query,
      resultsCount,
      ...rest
    }: PropsWithChildren<SearchbarProperties>,
    reference,
  ): React.ReactElement => {
    const isMobile = useIsMobile();

    const inputReference = useRef<HTMLInputElement>(null);

    return (
      <Flex {...rest} align="stretch" ref={reference}>
        <InputGroup position="relative">
          <Input
            autoFocus={autoFocus}
            borderRightRadius={children ? 0 : undefined}
            data-testid="searchbar:input"
            defaultValue={defaultValue}
            isDisabled={isDisabled}
            onChange={(e) => onQueryChange?.(e.target.value)}
            placeholder={placeholder}
            ref={inputReference}
            type="search"
            value={query}
          />
          <InputRightElement mr={2} width="auto">
            <HStack alignItems="stretch">
              {!isLoading && resultsCount != null && (
                <Badge colorScheme="blue">
                  {isMobile ? `${resultsCount}` : `${resultsCount} résultats`}
                </Badge>
              )}
              {isLoading && (
                <Badge colorScheme="blue">
                  <Spinner size="xs" />
                </Badge>
              )}
              {inputAddons}
            </HStack>
          </InputRightElement>
        </InputGroup>
        {children && (
          <Flex
            align="center"
            bg="gray.200"
            borderRightRadius="lg"
            direction="row"
          >
            {children}
          </Flex>
        )}
        {onSearchButtonClick && (
          <Button
            colorScheme="green"
            data-testid="searchbar:search-button"
            ml={2}
            onClick={onSearchButtonClick}
          >
            <SearchIcon />
          </Button>
        )}
      </Flex>
    );
  },
);
