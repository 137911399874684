import { Unit, UnitPage } from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";
import { WithSearchableGetPage } from "./mixins/WithSearchableGetPage";

export class Endpoint extends BaseEndpoint("units") {}

export const UnitEndpoint = WithSearchableGetPage<
  typeof Endpoint,
  Unit,
  UnitPage
>(Endpoint);
