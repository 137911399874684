import { Country, SectionSummary, Supplier } from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";
import { WithGetAll } from "./mixins/WithGetAll";

export class Endpoint extends BaseEndpoint("countries") {
  getSections(countryId: string): Promise<SectionSummary[]> {
    return this.request(`${countryId}/sections`);
  }

  getSuppliers(countryId: string): Promise<Supplier[]> {
    return this.request(`${countryId}/suppliers`);
  }
}

export const CountryEndpoint = WithGetAll<typeof Endpoint, Country>(Endpoint);
