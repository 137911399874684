import {
  CreateWholesalerProduct,
  WholesalerProduct,
  WholesalerProductPage,
} from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";
import { WithEdit } from "./mixins/WithEdit";
import { WithSearchableGetPage } from "./mixins/WithSearchableGetPage";
import { GetWholesalerProductsQuery } from "./query/GetWholesalerProductsQuery";

export class Endpoint extends BaseEndpoint("wholesaler-products") {}

const withGetPage = WithSearchableGetPage<
  typeof Endpoint,
  WholesalerProduct,
  WholesalerProductPage,
  GetWholesalerProductsQuery
>(Endpoint);

export const WholesalerProductEndpoint = WithEdit<
  typeof withGetPage,
  WholesalerProduct,
  CreateWholesalerProduct
>(withGetPage);
