const styles = {
  color: {
    primary: "#482ff7",
    secondary: "#2d6cdf",
    tercary: "#46c3db",
    white: "#eee",
  },
  global: {
    ".chakra-collapse": {
      overflow: "initial !important",
    },
    "@media (hover: none)": {
      ".chakra-accordion__button:hover": {
        background: "none !important",
      },
    },

    body: {
      color: "gray.700",
    },
  },
};

export default styles;
