import firebase from "firebase/app";

import { env } from "@/env";
import { errors } from "@/features/api/const/errors";
import { handleApiError } from "@/features/api/utils/handleApiError";
import { getTrace } from "@/firebase";
import { ApiClient as Client } from "@meditect/medibase-api-client";
import { isUUID } from "@meditect/medibase-components";

export type RequesterOptions = {
  showErrorModal?: boolean;
};

export const requester = async (
  url: RequestInfo,
  options?: RequesterOptions & RequestInit,
): Promise<Response> => {
  const { showErrorModal, ...init } = {
    ...options,
    showErrorModal: options?.showErrorModal,
  };
  const name = new URL(url.toString()).pathname
    .split("/")
    .reverse()
    .find((path) => path !== "" && !isUUID(path));
  const trace = getTrace(`${init?.method?.toLowerCase() ?? "get"}_${name}`);
  trace.start();
  const response = await fetch(url, init);
  trace.stop();
  if (!response.ok && response.status >= 400 && response.status < 500) {
    const { message, ...extra } = await response.json();
    if (message in errors) {
      if (showErrorModal !== false) {
        handleApiError(message, extra);
      }
      throw new Error(message, { cause: extra });
    }
    throw new Error("Invalid response status: " + response.status);
  }
  return response;
};

export const apiClient = new Client(env.REACT_APP_API_URL, {
  authProvider: () => firebase.auth().currentUser?.getIdToken() ?? null,
  requester,
});
