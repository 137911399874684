import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "@/store";

export interface ApiError<ExtraT = Record<string, unknown>> {
  extra: ExtraT;
  message: string;
}

export interface ApiSliceState {
  errors: ApiError[];
}

const initialState: ApiSliceState = {
  errors: [],
};

export const apiSlice = createSlice({
  initialState,
  name: "api",
  reducers: {
    addApiError: (state, { payload }: PayloadAction<ApiError>) => {
      state.errors = structuredClone([...state.errors, payload]);
    },
    popApiError: (state) => {
      // eslint-disable-next-line unicorn/no-useless-spread
      state.errors = structuredClone([...state.errors.slice(1)]);
    },
  },
});

export default apiSlice.reducer;

export const { addApiError, popApiError } = apiSlice.actions;

export const selectTopApiError = (state: RootState): ApiError | undefined =>
  state.api.errors[0];
