import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "@/store";

export interface SaleSlicePrintDetailsState {
  address?: string;
  name: string;
}

export interface SaleSliceState {
  printDetails: SaleSlicePrintDetailsState | undefined;
}
export const initialState: SaleSliceState = {
  printDetails: undefined,
};

export const salesSlice = createSlice({
  initialState,
  name: "sales",
  reducers: {
    setPrintDetails: (
      state,
      { payload }: PayloadAction<SaleSlicePrintDetailsState>,
    ) => {
      state.printDetails = payload;
    },
  },
});

export default salesSlice.reducer;

export const { setPrintDetails } = salesSlice.actions;

export const selectPrintDetails = (
  state: RootState,
): SaleSlicePrintDetailsState | undefined => {
  return state.sales.printDetails;
};
