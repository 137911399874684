import { Bill, CreateBill, UpdateBill } from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";
import { WithEdit } from "./mixins/WithEdit";
import { WithGet } from "./mixins/WithGet";

export class Endpoint extends BaseEndpoint("bills") {}

const withRead = WithGet<typeof Endpoint, Bill>(Endpoint);

export const BillEndpoint = WithEdit<
  typeof withRead,
  Bill,
  CreateBill,
  UpdateBill
>(withRead);
