// theme.js
const colors: Record<string, unknown> = {
  blue: {
    "50": "#EAF4FB",
    "100": "#C4DFF3",
    "200": "#9DCBEB",
    "300": "#77B7E4",
    "400": "#51A2DC",
    "500": "#247CB9",
    "600": "#2272AA",
    "700": "#1A557F",
    "800": "#113955",
    "900": "#091C2B",
  },
  cyan: {
    "50": "#DEF5FA",
    "100": "#C5F2FA",
    "200": "#5CD5ED",
    "300": "#0FC2E6",
    "400": "#0CA2C0",
    "500": "#0A8199",
    "600": "#097186",
    "700": "#076173",
    "800": "#065160",
    "900": "#05414D",
  },
  gray: {
    "0": "#FFFFFF",
    "50": "#F7FAFC",
    "100": "#EDF2F7",
    "200": "#E2E8F0",
    "300": "#CBD5E0",
    "400": "#A0AEC0",
    "500": "#718096",
    "600": "#4A5568",
    "700": "#2D3748",
    "800": "#1A202C",
    "900": "#171923",
  },
  green: {
    "50": "#E6F5F1",
    "100": "#D6EAE4",
    "200": "#A9D4C6",
    "300": "#7BB8A3",
    "400": "#58A689",
    "500": "#17875C",
    "600": "#126947",
    "700": "#0F573B",
    "800": "#0C4630",
    "900": "#093424",
  },
  orange: {
    "50": "#FFF8E5",
    "100": "#FFECB8",
    "200": "#FFE08A",
    "300": "#FFD35C",
    "400": "#FFC72E",
    "500": "#FFBB00",
    "600": "#E0A500",
    "700": "#B88700",
    "800": "#8F6900",
    "900": "#664B00",
  },
  overlay: "#846E48CC",
  overlayLight: "#F9FCFBCC",
  pink: {
    "50": "#FFEBEF",
    "100": "#F2C4DC",
    "200": "#EA9FC5",
    "300": "#E279AF",
    "400": "#D95398",
    "500": "#D12E82",
    "600": "#A72568",
    "700": "#7E1B4E",
    "800": "#541234",
    "900": "#2A091A",
  },
  purple: {
    "50": "#F5EBFF",
    "100": "#E9D8FD",
    "200": "#D6BCFA",
    "300": "#B794F4",
    "400": "#9F7AEA",
    "500": "#805AD5",
    "600": "#6B46C1",
    "700": "#6B46C1",
    "800": "#44337A",
    "900": "#322659",
  },
  red: {
    "50": "#FDEFED",
    "100": "#F8CEC9",
    "200": "#F3ADA5",
    "300": "#EE8C81",
    "400": "#E96B5D",
    "500": "#E0331F",
    "600": "#C52C1B",
    "700": "#B42818",
    "800": "#A22416",
    "900": "#902014",
  },
  teal: {
    "50": "#DCF7F7",
    "100": "#C2EDED",
    "200": "#67CBC9",
    "300": "#49C1BF",
    "400": "#39A7A5",
    "500": "#2C8281",
    "600": "#2A7A78",
    "700": "#246A69",
    "800": "#1F5B5A",
    "900": "#1A4C4B",
  },
};

colors.brand = colors.green;
colors.success = colors.green;
colors.info = colors.blue;
colors.warning = colors.orange;
colors.danger = colors.red;

export default colors;
