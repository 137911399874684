import { Duration } from "date-fns";

const SECONDS_IN_A_MINUTE = 1 * 60;
const SECONDS_IN_AN_HOUR = SECONDS_IN_A_MINUTE * 60;
const SECONDS_IN_A_DAY = SECONDS_IN_AN_HOUR * 24;
const SECONDS_IN_A_WEEK = SECONDS_IN_A_DAY * 7;
const SECONDS_IN_A_YEAR = SECONDS_IN_A_DAY * 365;
const SECONDS_IN_A_MONTH = SECONDS_IN_A_YEAR / 12;

const mapping: Record<keyof Duration, { seconds: number }> = {
  days: { seconds: SECONDS_IN_A_DAY },
  hours: { seconds: SECONDS_IN_AN_HOUR },
  minutes: { seconds: SECONDS_IN_A_MINUTE },
  months: { seconds: SECONDS_IN_A_MONTH },
  seconds: { seconds: 1 },
  weeks: { seconds: SECONDS_IN_A_WEEK },
  years: { seconds: SECONDS_IN_A_YEAR },
};

// eslint-disable-next-line unicorn/no-anonymous-default-export
export default function (duration: Duration): number {
  return Object.entries(duration).reduce((seconds, [key, value]) => {
    seconds += mapping[key as keyof Duration].seconds * value;
    return seconds;
  }, 0);
}
