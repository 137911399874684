const buildEnvironment = <KeyT extends string>(
  keys: KeyT[],
): Record<KeyT, string> => {
  const returnValue = {} as Record<KeyT, string>;
  for (const key of keys) {
    returnValue[key] = import.meta.env[key] as string;
  }
  return returnValue;
};

export const env = buildEnvironment([
  "REACT_APP_FUNCTIONS_URL",
  "REACT_APP_API_URL",
  "REACT_APP_FIREBASE_API_KEY",
  "REACT_APP_FIREBASE_AUTH_DOMAIN",
  "REACT_APP_FIREBASE_PROJECT_ID",
  "REACT_APP_FIREBASE_APP_ID",
  "REACT_APP_FIREBASE_MEASUREMENT_ID",
  "REACT_APP_FIREBASE_STORAGE_BUCKET",
  "REACT_APP_FEATURE_DISABLED",
  "REACT_APP_HELP_URL",
  "REACT_APP_PAGINATION_CHUNK_SIZE",
]);
