import { BaseEndpoint } from "./BaseEndpoint";
import { StatusResponseMCF } from "../dtos";

export class Endpoint extends BaseEndpoint("mecef") {
  getStatus(body: { MCFToken: string }): Promise<StatusResponseMCF> {
    return this.request(`test-token`, { method: "POST", body });
  }
}

export const EMECeFEndpoint = Endpoint;
