import { Brand, BrandPage, CreateBrand, UpdateBrand } from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";
import { WithEdit } from "./mixins/WithEdit";
import { WithSearchableGetPage } from "./mixins/WithSearchableGetPage";

export class Endpoint extends BaseEndpoint("brands") {}

const withSearch = WithSearchableGetPage<typeof Endpoint, Brand, BrandPage>(
  Endpoint,
);

export const BrandEndpoint = WithEdit<
  typeof withSearch,
  Brand,
  CreateBrand,
  UpdateBrand
>(withSearch);
