import { ProductCategory } from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";
import { WithEdit } from "./mixins/WithEdit";
import { WithGetAll } from "./mixins/WithGetAll";

export class Endpoint extends BaseEndpoint("product-categories") {}

const withEdit = WithEdit<typeof Endpoint, ProductCategory>(Endpoint);

export const CategoryEndpoint = WithGetAll<typeof withEdit, ProductCategory>(
  withEdit,
);
