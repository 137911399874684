import { Report } from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";

export class ReportEndpoint extends BaseEndpoint("reports") {
  create(report: Report): Promise<Report> {
    return this.request("", {
      body: report,
      method: "POST",
    });
  }
}
