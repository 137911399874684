import { ComponentStyleConfig } from "@chakra-ui/react";

const table: ComponentStyleConfig = {
  baseStyle: {
    table: {
      shadow: "sm",
    },
    td: {
      backgroundColor: `white`,
      borderBottom: "none",
    },
    th: {
      _first: {
        borderTopStartRadius: "lg",
      },
      _last: {
        borderTopEndRadius: "lg",
      },
      backgroundColor: "white",
    },
  },
  variants: {
    print: () => ({
      td: {
        fontSize: "10pt",
        padding: "2pt 4pt",
      },
      tfoot: {
        borderColor: "black",
        borderTop: "1px",
      },
      th: {
        borderBottom: "1px",
        borderColor: "black",
        color: "black",
        fontSize: "10pt",
        fontWeight: 600,
        padding: "2pt 4pt",
        textAlign: "start",
        textTransform: "none",
      },
    }),
    simple: (properties) => ({
      th: {
        borderBottom: "2px",
        borderColor: `${properties.colorScheme}.500`,
        color: "gray.700",
        fontSize: "md",
        fontWeight: "medium",
        textTransform: "none",
      },
    }),
  },
};

export default table;
