export const formLabel = {
  baseStyle: {
    _disabled: {
      opacity: 1,
    },
    fontWeight: "semibold",
    opacity: 1,
  },
};

export default formLabel;
