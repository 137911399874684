import { forwardRef, LegacyRef, ReactElement, ReactNode } from "react";

import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProperties,
} from "@meditect/medibase-components";

type ButtonProperties = { children?: ReactNode } & ChakraButtonProperties;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Button = forwardRef<ButtonProperties, any>(
  (properties: ButtonProperties, reference): null | ReactElement => {
    return (
      <ChakraButton
        {...properties}
        ref={reference as LegacyRef<HTMLButtonElement>}
        sx={
          properties.variant === "action"
            ? {
                ...properties.sx,
                "& > span > svg": {
                  color: "green.500",
                },
              }
            : properties.sx
        }
      />
    );
  },
);
