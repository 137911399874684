import { env } from "@/env";

export const OFFLINE_EXPIRATION = 300; // 5minutes

export const MAX_NB_RETRY = 5;
export const RETRY_DELAY_MS = 200;

export const PAGINATION_CHUNK_SIZE = +(
  env.REACT_APP_PAGINATION_CHUNK_SIZE ?? 10
);
