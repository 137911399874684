const card = {
  background: "white",
  borderRadius: "lg",
  boxShadow: "base",
  padding: 4,
};

const cardSmooth = {
  ...card,
  borderRadius: "base",
  boxShadow: "base",
  padding: 6,
};

export default {
  card,
  cardSmooth,
};
