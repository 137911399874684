import {
  chakra,
  Divider,
  Heading,
  HStack,
  Stack,
  VStack,
} from "@chakra-ui/react";
import { PropsWithChildren } from "react";

export interface CollapseCardItemWithIconProperties {
  hasDelimiters?: boolean;
  icon?: React.ReactNode;
  title?: React.ReactNode;
}

export const CollapseCardItemWithIcon = chakra(
  ({
    children,
    hasDelimiters = true,
    icon,
    title,
  }: PropsWithChildren<CollapseCardItemWithIconProperties>) => {
    return (
      <Stack mt={hasDelimiters ? 2 : 0}>
        {hasDelimiters && <Divider />}
        <VStack style={{ alignItems: "start" }}>
          <HStack>
            {icon}
            <Heading color="gray.500" fontWeight="semibold" size="sm">
              {title}
            </Heading>
          </HStack>
          {children}
        </VStack>
      </Stack>
    );
  },
);
