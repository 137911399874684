const textarea = {
  defaultProps: {
    variants: "outline",
  },
  variants: {
    outline: {
      _focus: {
        bg: "white",
      },
      _hover: {
        bg: "blue.50",
        borderColor: "blue.300",
      },
      _placeholder: {
        color: "gray.500",
      },
      bg: "white",
      borderColor: "gray.300",
      color: "gray.700",
    },
    readonly: {
      bgColor: "gray.50",
      border: "1px solid",
      borderColor: "gray.200",
      textAlign: "center",
      type: "number",
    },
  },
};

export default textarea;
