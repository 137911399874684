import { User } from "@meditect/medibase-api-client";

export interface FormatOptions {
  defaultValue?: string;
  full?: boolean;
}

export const formatUserName = (
  contact: Pick<User, "email" | "firstName" | "lastName"> | undefined,
  { defaultValue = "Inconnu", full = true }: FormatOptions = {},
): string => {
  if (contact == null) {
    return defaultValue;
  }
  if (contact.lastName == null) {
    return contact.firstName ?? contact.email ?? defaultValue;
  }

  const lastName = (
    full ? contact.lastName : contact.lastName.slice(0, 1) + "."
  ).toUpperCase();

  if (contact.firstName == null) {
    return lastName;
  }
  return full
    ? `${lastName} ${contact.firstName}`
    : `${contact.firstName} ${lastName}`;
};
