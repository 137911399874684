import {
  CreatePharmacyProductStorage,
  GetManyPharmacyProductStorage,
  PharmacyProductStorage,
} from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";
import { WithEdit } from "./mixins/WithEdit";

export class Endpoint extends BaseEndpoint("pharmacy-product-storages") {
  getAll(
    query?: GetManyPharmacyProductStorage,
  ): Promise<PharmacyProductStorage[]> {
    return this.request("", { query });
  }
}

export const PharmacyProductStorageEndpoint = WithEdit<
  typeof Endpoint,
  PharmacyProductStorage,
  CreatePharmacyProductStorage
>(Endpoint);
