import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import {
  actionTypes,
  constants,
  firebaseReducer,
  getFirebase,
} from "react-redux-firebase";

import apiReducer from "./features/api/apiSlice";
import billingReducer from "./pages/financing/billing/billingSlice";
import salesReducer from "./pages/sales/salesSlice";

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          // just ignore every redux-firebase and react-redux-firebase action type
          ...Object.keys(constants.actionTypes).map(
            (type) => `@@reactReduxFirebase/${type}`,
          ),
          ...Object.keys(actionTypes).map(
            (type) => `@@reactReduxFirebase/${type}`,
          ),
        ],
        ignoredPaths: ["firebase", "firestore"],
      },
      thunk: {
        extraArgument: {
          getFirebase,
        },
      },
    }),
  reducer: {
    api: apiReducer,
    billing: billingReducer,
    firebase: firebaseReducer,
    sales: salesReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
