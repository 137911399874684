import { Disbursement, CreateDisbursement } from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";
import { WithCreate } from "./mixins/WithCreate";
import { WithGet } from "./mixins/WithGet";

export class Endpoint extends BaseEndpoint("disbursements") {}

export const withCreate = WithCreate<
  typeof Endpoint,
  Disbursement,
  CreateDisbursement
>(Endpoint);

export const DisbursementEndpoint = WithGet<typeof withCreate, Disbursement>(
  withCreate,
);
