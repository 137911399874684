import {
  CashRegister,
  CashRegisterStatus,
  CashRegisterSummary,
  CreateCashRegister,
  Timespan,
  UpdateCashRegister,
} from "../dtos";
import { CashRegisterReport } from "../dtos/CashRegisterReport";
import { BaseEndpoint } from "./BaseEndpoint";
import { Page } from "./domain";
import { WithEdit } from "./mixins/WithEdit";
import { GetPageQuery } from "./query";

export class Endpoint extends BaseEndpoint("cash-registers") {
  get(cashRegisterId: string): Promise<CashRegister | null> {
    return this.request(`${cashRegisterId}`);
  }

  getMany(
    query?: GetPageQuery &
      Partial<Timespan> & { userId?: string; status: CashRegisterStatus },
  ): Promise<Page<CashRegisterSummary>> {
    return this.request("", { query });
  }

  async getCurrentByUserId(userId: string): Promise<CashRegister | null> {
    const res = await this.request<{ cashRegister: CashRegister | null }>(
      `current`,
      {
        query: { userId },
      },
    );
    return res.cashRegister;
  }

  getReport(query: { timespan?: Timespan }): Promise<CashRegisterReport> {
    return this.request("report", { query });
  }
}

export const CashRegisterEndpoint = WithEdit<
  typeof Endpoint,
  CashRegister,
  CreateCashRegister,
  UpdateCashRegister
>(Endpoint);
