import { CreateOrUpdateVatRate, VatRate } from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";
import { WithEdit } from "./mixins/WithEdit";
import { WithGetAll } from "./mixins/WithGetAll";

export class Endpoint extends BaseEndpoint("vat-rates") {}

const withEdit = WithEdit<typeof Endpoint, VatRate, CreateOrUpdateVatRate>(
  Endpoint,
);

export const VatRateEndpoint = WithGetAll<typeof withEdit, VatRate>(withEdit);
