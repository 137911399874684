import { apiClient } from "@/features/api/apiClient";
import fetchLocalData from "@/features/offline/utils/fetchLocalData";
import { Organization } from "@meditect/medibase-api-client";

import { Slice } from "../boundStore";

export type OrganizationSlice = {
  error: boolean;
  fetchOrganizations: () => Promise<void>;
  isFetching: boolean;
  organizations: Organization[];

  replaceOrganization: (comapny: Organization) => void;
  resetData: () => void;
  updatedAt: Date | undefined;
};

export const createOrganizationSlice: Slice<OrganizationSlice> = (
  set,
  get,
) => ({
  error: false,
  // Fetcher
  fetchOrganizations: async () =>
    fetchLocalData(
      set,
      get,
      "organization.organizations",
      async () => await apiClient.organizations.getAll(),
    ),
  isFetching: false,
  // State
  organizations: [],

  // Setter
  replaceOrganization: (organization) => {
    set(({ organization: state }) => {
      const organizationIndex = state.organizations.findIndex(
        (p) => p.id === organization.id,
      );
      if (organizationIndex > -1) {
        state.organizations[organizationIndex] = organization;
      }
      state.organizations.push(organization);
    });
  },

  resetData: () =>
    set(({ organization: state }) => {
      state.organizations = [];
      state.error = false;
      state.updatedAt = undefined;
    }),

  updatedAt: undefined,
});
