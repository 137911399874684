import { ComponentStyleConfig } from "@chakra-ui/react";

const text: ComponentStyleConfig = {
  baseStyle: {
    fontSize: { base: "sm", sm: "md" },
  },
  variants: {
    auxiliary: {
      color: "gray.500",
      fontSize: { base: "xs", sm: "sm" },
    },
    error: {
      color: "red.700",
      fontWeight: "500",
    },
    link: {
      color: "info.500",
      cursor: "pointer",
      fontSize: { base: "sm", sm: "md" },
      fontWeight: "medium",
      lineHeight: "6",
      textDecoration: "underline",
    },
    main: {
      fontSize: { base: "sm", sm: "md" },
      fontWeight: "medium",
      lineHeight: "6",
    },
  },
};

export default text;
