import { addApiError } from "@/features/api/apiSlice";
import { store } from "@/store";

export const handleApiError = (
  message: string,
  extra: Record<string, unknown>,
): void => {
  store.dispatch(
    addApiError({
      extra,
      message,
    }),
  );
};
