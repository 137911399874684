import { Packaging, PackagingPage } from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";
import { WithSearchableGetPage } from "./mixins/WithSearchableGetPage";

export class Endpoint extends BaseEndpoint("packagings") {}

export const PackagingEndpoint = WithSearchableGetPage<
  typeof Endpoint,
  Packaging,
  PackagingPage
>(Endpoint);
