import { env } from "@/env";

export async function isConnected(): Promise<boolean> {
  return await fetch(
    env.REACT_APP_API_URL ||
      "http://localhost:5001/medibase-dev/europe-west1/api/",
    { method: "HEAD" },
  )
    .then(() => true)
    .catch(() => false);
}
