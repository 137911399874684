import { EndpointType } from "../BaseEndpoint";
import { WithGet } from "./WithGet";

export type WithGetAllType<T> = {
  getAll(): Promise<T[]>;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const WithGetAll = <EndpointT extends EndpointType, T, AllT = T[]>(
  clazz: EndpointT,
) => {
  class Base extends clazz {
    /**
     * Get all of the resources
     */
    getAll(): Promise<AllT> {
      return this.request();
    }
  }

  return WithGet<typeof Base, T>(Base);
};
