import { ErrorMessageComponent } from "@/features/api/domain/ErrorMessageComponent";
import { Organization } from "@meditect/medibase-api-client";
import { ListItem, Text, UnorderedList } from "@meditect/medibase-components";

export interface OrganizationsArchiveUnclosedBillOrSalesErrorExtra {
  billCount: number;
  organization: Organization;
  saleCount: number;
}

export const OrganizationsArchiveUnclosedBillOrSalesError: ErrorMessageComponent<
  OrganizationsArchiveUnclosedBillOrSalesErrorExtra
> = ({ extra: { billCount, organization, saleCount } }) => {
  return (
    <>
      <Text>
        <Text as="span" mr={1}>
          L’archivage du client
        </Text>
        <Text as="b" mr={1}>
          {organization.name}
        </Text>
        <Text as="span">
          est actuellement impossible car ce client est utilisé dans :
        </Text>
      </Text>
      <UnorderedList mb={4} pl={4}>
        {saleCount > 0 && (
          <ListItem>
            <Text as="b" mr={1}>
              {saleCount}
            </Text>
            <Text as="span">vente(s) non clôturée(s)</Text>
          </ListItem>
        )}
        {billCount > 0 && (
          <ListItem>
            <Text as="b" mr={1}>
              {billCount}
            </Text>
            <Text as="span">facture(s) non clôturée(s)</Text>
          </ListItem>
        )}
      </UnorderedList>
      <Text>
        <Text as="span" mr={1}>
          Veillez à clôturer ces éléments, et vous pourrez ensuite archiver
        </Text>
        <Text as="b">{organization.name}</Text>
        <Text as="span">.</Text>
      </Text>
    </>
  );
};
