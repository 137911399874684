import { defineStyleConfig } from "@chakra-ui/react";

const menuTheme = defineStyleConfig({
  baseStyle: {
    list: {
      shadow: "lg",
      zIndex: "popover",
    },
  },
});

export default menuTheme;
