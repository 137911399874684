import { Type } from "../../domain";
import { EndpointType } from "../BaseEndpoint";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export interface WithCreateType<T, CreateT> {
  create(
    body: CreateT,
    options?: {
      showErrorModal?: boolean;
    },
  ): Promise<T>;
}

export const WithCreate = <EndpointT extends EndpointType, T, CreateT = T>(
  clazz: EndpointT,
): Type<WithCreateType<T, CreateT>> & EndpointT =>
  class extends clazz {
    /**
     * Create a new resource
     *
     * @param body Payload used to create the new resource
     */
    create(
      body: CreateT,
      options?: {
        showErrorModal?: boolean;
      },
    ): Promise<T> {
      return this.request("", {
        method: "POST",
        body,
        ...options,
      });
    }
  };
