import { createContext } from "react";

export type Claims = {
  claims?: Record<string, number>;
  domainId?: string;
  flags?: number;
  parentId?: string;
  pharmacyId?: string;
  pin?: boolean;
  roles: string[];
};

export interface ClaimsContextValue {
  claims: Claims | undefined;
  isLoading: boolean;
}

export const ClaimsContextDefaultValue: ClaimsContextValue = {
  claims: undefined,
  isLoading: false,
};

export const ClaimsContext = createContext<ClaimsContextValue>(
  ClaimsContextDefaultValue,
);
