import { CreatePharmacy, Pharmacy, PharmacyPage, Role, User } from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";
import { WithEdit } from "./mixins/WithEdit";
import { WithSearchableGetPage } from "./mixins/WithSearchableGetPage";

export class Endpoint extends BaseEndpoint("pharmacies") {
  getRoles(id: string): Promise<Role[]> {
    return this.request(`${id}/roles`);
  }
  getUsers(id: string): Promise<User[]> {
    return this.request(`${id}/users`);
  }
}

const withGetAll = WithSearchableGetPage<
  typeof Endpoint,
  Pharmacy,
  PharmacyPage
>(Endpoint);

export const PharmacyEndpoint = WithEdit<
  typeof withGetAll,
  Pharmacy,
  CreatePharmacy
>(withGetAll);
