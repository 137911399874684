import { Box, Image, Text } from "@chakra-ui/react";

export interface LoaderProperties {
  description?: React.ReactNode;
}

export const Loader = ({ description }: LoaderProperties) => {
  return (
    <Box textAlign="center">
      <Image
        alt="loading"
        m="auto"
        maxWidth="7em"
        src="/spinner.svg"
        width="33vw"
      />
      <Text
        color="brand.700"
        fontSize="clamp(0.75em, 1vw, 1em)"
        fontWeight="bold"
        mt="4"
      >
        {description ?? "Chargement"}
      </Text>
    </Box>
  );
};
