import { useContext, useEffect, useState } from "react";
import { useFirebase } from "react-redux-firebase";

import {
  Claims,
  ClaimsContext,
} from "@/features/auth/components/ClaimsContext";
import { useBoundStore } from "@/features/offline/stores/boundStore";
import {
  SentryContext,
  SentryContextValue,
} from "@/features/sentry/SentryProvider";
import { ComponentWithChildren } from "@/utils/types/ComponentsWithChildren";

export const ClaimsProvider = ({ children }: ComponentWithChildren) => {
  const firebase = useFirebase();
  const [isLoading, setIsLoading] = useState(true);
  const [claims, setClaims] = useState<Claims | undefined>();
  const oldPharmacyId = useBoundStore(
    (state: { pharmacyId: any }) => state.pharmacyId,
  );
  const [resetData, setZustandClaims] = useBoundStore(
    (store: { resetData: any; setClaims: any }) => [
      store.resetData,
      store.setClaims,
    ],
  );

  const { setMetadata } = useContext<SentryContextValue>(SentryContext);

  useEffect(() => {
    setIsLoading(true);
    return firebase.auth().onIdTokenChanged(async (user) => {
      if (user == null) {
        setClaims(undefined);
        setZustandClaims(undefined);
      } else {
        const decoded = await user.getIdTokenResult();
        const claims = decoded.claims as Claims;
        setClaims(claims);
        setMetadata(user, claims);
        setZustandClaims(claims);
        if (claims.pharmacyId && claims.pharmacyId !== oldPharmacyId) {
          resetData(claims);
        }
      }
      setIsLoading(false);
    });
  }, [
    firebase,
    oldPharmacyId,
    resetData,
    setClaims,
    setIsLoading,
    setMetadata,
    setZustandClaims,
  ]);

  return (
    <ClaimsContext.Provider
      value={{
        claims,
        isLoading,
      }}
    >
      {children}
    </ClaimsContext.Provider>
  );
};
