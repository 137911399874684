import { BaseEndpoint } from "./BaseEndpoint";
import { WithGet } from "./mixins/WithGet";
import { WithSearchableGetPage } from "./mixins/WithSearchableGetPage";
import {
  GetManyRewardPrograms,
  RewardProgram,
  RewardProgramPeriod,
} from "../dtos";
import { Page } from "./domain";

class Endpoint extends BaseEndpoint("reward-programs") {
  async getPeriod(id: string): Promise<RewardProgramPeriod | null> {
    return this.request(`period/${id}`);
  }
}

const withGet = WithGet<typeof Endpoint, RewardProgram>(Endpoint);

export const RewardProgramEndpoint = WithSearchableGetPage<
  typeof withGet,
  RewardProgram,
  Page<RewardProgram>,
  GetManyRewardPrograms
>(withGet);
