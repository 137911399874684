import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";

import { ClaimsProvider } from "@/features/auth/components/ClaimsProvider";
import { localStoragePersister, queryClient } from "@/queryClient";
import { MedibaseComponentsProvider } from "@meditect/medibase-components";
import "@meditect/medibase-components/index.css";

import App from "./App";
import { UserProvider } from "./features/auth/components/UserProvider";
import "./features/sentry/instrument";
import { SentryProvider } from "./features/sentry/SentryProvider";
import { getFirebase } from "./firebase";
import "./index.css";
import { store } from "./store";

const reactReduxFirebaseProperties = getFirebase(store);
const container = document.querySelector("#root");
// React documentation provide this line
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <SentryProvider>
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister: localStoragePersister }}
    >
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...reactReduxFirebaseProperties}>
          <ClaimsProvider>
            <MedibaseComponentsProvider>
              <HelmetProvider>
                <UserProvider>
                  <App />
                </UserProvider>
              </HelmetProvider>
            </MedibaseComponentsProvider>
          </ClaimsProvider>
        </ReactReduxFirebaseProvider>
      </Provider>
      <ReactQueryDevtools />
    </PersistQueryClientProvider>
  </SentryProvider>,
);
