import {
  Order,
  OrderPage,
  SearchGetManyOrdersQueryParams,
  UpdateOrder,
} from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";
import { WithDelete } from "./mixins/WithDelete";
import { WithGet } from "./mixins/WithGet";
import { WithSearchableGetPage } from "./mixins/WithSearchableGetPage";
import { WithUpdate } from "./mixins/WithUpdate";

interface CsvQuery {
  hasWholesalerCode: boolean;
}
export class Endpoint extends BaseEndpoint("orders") {
  csv(query: CsvQuery, id?: string): Promise<Blob> {
    return this.request(`${id}/csv`, { query, type: "blob" });
  }
  getExportData(id?: string): Promise<unknown[]> {
    return this.request(`${id}/export-data`);
  }
}

const withGetOne = WithGet<typeof Endpoint, Order>(Endpoint);

const withGetAll = WithSearchableGetPage<
  typeof withGetOne,
  Order,
  OrderPage,
  SearchGetManyOrdersQueryParams
>(withGetOne);

const withUpdate = WithUpdate<typeof withGetAll, Order, UpdateOrder>(
  withGetAll,
);

export const OrderEndpoint = WithDelete(withUpdate);
