import {
  CreateProduct,
  PharmacyProduct,
  Product,
  ProductNotice,
  ProductSPC,
  ProductSummaryPage,
  ProductWithDetails,
  UpdateProduct,
} from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";
import { WithEdit } from "./mixins/WithEdit";
import { WithGet } from "./mixins/WithGet";
import { GetProductsQuery } from "./query";

export class Endpoint extends BaseEndpoint("products") {
  getDetails(productId: string): Promise<ProductWithDetails> {
    return this.request(`${productId}/details`);
  }

  getPage(query?: GetProductsQuery): Promise<ProductSummaryPage> {
    return this.request("", { query });
  }

  getPharmacyProducts(productId: string): Promise<PharmacyProduct[]> {
    return this.request(`${productId}/pharmacy-products`);
  }

  async getNotice(productId: string): Promise<ProductNotice | null> {
    const { notice } = (await this.request(`${productId}/notice`)) as {
      notice: ProductNotice;
    };
    return notice ?? null;
  }

  async getSPC(productId: string): Promise<ProductSPC | null> {
    const { spc } = (await this.request(`${productId}/spc`)) as {
      spc: ProductSPC;
    };
    return spc ?? null;
  }
}

const withGet = WithGet<typeof Endpoint, Product>(Endpoint);

export const ProductEndpoint = WithEdit<
  typeof withGet,
  Product,
  CreateProduct,
  UpdateProduct
>(withGet);

export class ProductBatchEndpoint extends BaseEndpoint("product-batches") {
  createBatch(products: CreateProduct[]): Promise<Product[]> {
    return this.request("", { body: products, method: "POST" });
  }
}
