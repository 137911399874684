import { Component, ErrorInfo, ReactNode } from "react";

import { ErrorPage } from "@/pages/error/ErrorPage";
import { Flex } from "@meditect/medibase-components";

export class DynamicModuleErrorBoundary extends Component<
  { children: ReactNode; fallback?: ReactNode },
  { hasError: boolean }
> {
  constructor(properties: { children: ReactNode; fallback: ReactNode }) {
    super(properties);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    console.error("getDerivedStateFromError", error);
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.error("ComponentDidCatch", error, info.componentStack);
    if (error.message.includes("Failed to fetch dynamically imported module")) {
      window.location.reload();
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        this.props.fallback ?? (
          <Flex alignItems="center" h="100vh" justify="center" w="100%">
            <ErrorPage
              description={
                "Nous rencontrons un problème technique. Meditect met tout en oeuvre pour résoudre ce problème au plus vite. Merci de votre compréhension."
              }
              imageSrc={"/warning.svg"}
              title={"Toutes nos excuses!"}
            />
          </Flex>
        )
      );
    }

    return this.props.children;
  }
}
