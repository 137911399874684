// theme.js
import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";

import colors from "./colors";
import Badge from "./components/badge";
//import borders from "./foundations/borders"
import Button from "./components/button";
import FormControl from "./components/formControl";
import FormLabel from "./components/formLabel";
import Input from "./components/input";
import Link from "./components/link";
import Menu from "./components/menu";
import PinInput from "./components/pin-input";
import Popover from "./components/popover";
import Table from "./components/table";
import Tabs from "./components/tabs";
import Text from "./components/text";
import Textarea from "./components/textarea";
import { textStyles } from "./foundations/textStyles";
import layerStyles from "./layerStyles";
import styles from "./styles";

const overrides = {
  colors,
  // Other foundational style overrides go here
  components: {
    Badge,
    Button,
    Form: FormControl,
    FormLabel,
    Input,
    Link,
    Menu,
    PinInput,
    Popover,
    Table,
    Tabs,
    Text,
    Textarea,
  },
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  layerStyles,
  styles,
  //  borders,
  textStyles,
};

export const appTheme = extendTheme(
  overrides,
  withDefaultColorScheme({ colorScheme: "brand" }),
);
