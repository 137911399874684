import { Supplier, WholesalerProduct } from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";

export class WholesalerEndpoint extends BaseEndpoint("wholesalers") {
  getListingBySku(
    sellerId: string,
    sku: string,
    title?: string,
  ): Promise<WholesalerProduct> {
    return this.request(`${sellerId}/listings/${sku}`, { query: { title } });
  }

  getAll(): Promise<Supplier[]> {
    return this.request();
  }
}
