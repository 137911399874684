import {
  AuditAction,
  AuditActionPage,
  GetManyAuditActionsQueryParams,
} from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";
import { WithGet } from "./mixins/WithGet";

export class Endpoint extends BaseEndpoint("audit") {
  getPage(query: GetManyAuditActionsQueryParams): Promise<AuditActionPage> {
    return this.request("", { query });
  }
}

export const AuditEndpoint = WithGet<typeof Endpoint, AuditAction>(Endpoint);
