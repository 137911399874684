import {
  CloseOrderPreparation,
  GetRecordStat,
  OrderPreparationRecord,
  OrderPreparationRecordStat,
  SearchOrderPreparationRecords,
} from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";

export class Endpoint extends BaseEndpoint("order-preparations") {
  findRecords(
    query: SearchOrderPreparationRecords,
  ): Promise<OrderPreparationRecord[]> {
    return this.request("records", { query });
  }

  getRecordStat(query: GetRecordStat): Promise<OrderPreparationRecordStat> {
    return this.request("record", { query });
  }

  close(body: CloseOrderPreparation): Promise<void> {
    return this.request("", { method: "POST", body });
  }
}

export const OrderPreparationEndpoint = Endpoint;
