import {
  CreateProductView,
  ProductViewSummary,
  ProductViewSummaryPage,
} from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";
import { GetPageQuery } from "./query";

export class ProductViewEndpoint extends BaseEndpoint("product-views") {
  getPage(query: GetPageQuery = {}): Promise<ProductViewSummaryPage> {
    return this.request("", { query });
  }

  create(body: CreateProductView): Promise<ProductViewSummary> {
    return this.request("", { method: "POST", body });
  }
}
