import { ChakraProvider } from "@chakra-ui/react";
import { ReactNode } from "react";

import { appTheme } from "./theme";

export const MedibaseComponentsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <ChakraProvider resetCSS theme={appTheme}>
      {children}
    </ChakraProvider>
  );
};
