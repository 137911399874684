import type { Store } from "redux";

import "firebase/analytics";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/performance";
import "firebase/storage";
import { ReactReduxFirebaseProviderProps } from "react-redux-firebase";
import "web-vitals";

import { env as environment } from "./env";

export const getFirebase = (store: Store): ReactReduxFirebaseProviderProps => {
  const reactReduxFirebaseProperties: ReactReduxFirebaseProviderProps = {
    config: {},
    dispatch: store.dispatch,
    firebase,
  };

  if (firebase.apps.length === 0) {
    firebase.initializeApp({
      apiKey: environment.REACT_APP_FIREBASE_API_KEY,
      appId: environment.REACT_APP_FIREBASE_APP_ID,
      authDomain: environment.REACT_APP_FIREBASE_AUTH_DOMAIN,
      measurementId: environment.REACT_APP_FIREBASE_MEASUREMENT_ID,
      projectId: environment.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: environment.REACT_APP_FIREBASE_STORAGE_BUCKET,
    });
  } else {
    // if already initialized, use that one
    firebase.app();
  }

  // Initialize Performance Monitoring
  firebase.performance();

  return reactReduxFirebaseProperties;
};

// Get a custom trace for performance monitoring
export const getTrace = (name: string): firebase.performance.Trace =>
  firebase.performance().trace(name);
