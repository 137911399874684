const popover = {
  baseStyle: {
    content: {
      _focus: {
        shadow: "xl",
      },
      border: 0,
      borderTopRadius: 0,
      shadow: "xl",
    },
    popper: {
      zIndex: "popover",
    },
  },
  variants: {
    fullWidth: {
      content: {
        width: "full",
      },
      popper: {
        width: "full",
      },
    },
    responsive: {
      content: {
        width: {
          width: "full",
        },
      },
      popper: {
        width: {
          "2xl": "40%",
          base: "full",
          lg: "80%",
          xl: "60%",
        },
      },
    },
  },
};

export default popover;
