import * as Sentry from "@sentry/react";

import { env } from "@/env";

const getSentryEnvironment = () => {
  if (env.REACT_APP_API_URL.includes("oucbena3oa")) {
    return "dev";
  }
  if (env.REACT_APP_API_URL.includes("de7rfkefdq")) {
    return "preprod";
  }
  if (env.REACT_APP_API_URL.includes("jnjltw26ga")) {
    return "prod";
  }
  return "local";
};

Sentry.init({
  dsn: "https://bc9eaf4b7c49b9998bd8dcad4860cc2e@o4506970739113984.ingest.us.sentry.io/4506970740293632",
  enabled: getSentryEnvironment() !== "local",

  // Set tracesSampleRate to 1.0 to capture 100%
  environment: getSentryEnvironment(),

  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({ history }),
    Sentry.replayIntegration({
      blockAllMedia: false,
      maskAllInputs: false,
      maskAllText: false,
    }),
  ],

  // Capture Replay for 10% of all sessions,
  replaysOnErrorSampleRate: 1,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/api-/],
  // of transactions for performance monitoring.
  tracesSampleRate: 1,

  // Offline event capture
  transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
});
