import { DuplicateCodePinError } from "@/features/api/components/errors/DuplicateCodePinError";
import { FinancingInvalidMCFTokenProvidedError } from "@/features/api/components/errors/FinancingInvalidMCFTokenProvidedError";
import { OrganizationsArchiveUnclosedBillOrSalesError } from "@/features/api/components/errors/OrganizationsArchiveUnclosedBillOrSalesError";
import { PersonsArchiveUnclosedBillOrSalesError } from "@/features/api/components/errors/PersonsArchiveUnclosedBillOrSalesError";
import { RolesCannotDeleteRoleWithUsersError } from "@/features/api/components/errors/RolesCannotDeleteRoleWithUsersError";
import { SalesCannotCloseEmptySaleError } from "@/features/api/components/errors/SalesCannotCloseEmptySaleError";
import { SalesCannotCloseSaleFromBeninPharmacyWithoutMCFTokenError } from "@/features/api/components/errors/SalesCannotCloseSaleFromBeninPharmacyWithoutMCFTokenError";
import { SalesCannotCloseSaleWithInvalidPaymentError } from "@/features/api/components/errors/SalesCannotCloseSaleWithInvalidPaymentError";
import { SalesCannotCloseSaleWithNotEnoughPaidError } from "@/features/api/components/errors/SalesCannotCloseSaleWithNotEnoughPaidError";
import { SalesCannotCloseSaleWithoutPharmacyIdError } from "@/features/api/components/errors/SalesCannotCloseSaleWithoutPharmacyIdError";
import { SalesCannotCloseSaleWithSameContractPolicyTwiceError } from "@/features/api/components/errors/SalesCannotCloseSaleWithSameContractPolicyTwiceError";
import { SalesCannotCreateInvoiceEMECeFError } from "@/features/api/components/errors/SalesCannotCreateInvoiceEMECeFError";
import { SalesCannotCreateNewVersionOfSaleWithPaidBillsError } from "@/features/api/components/errors/SalesCannotCreateNewVersionOfSaleWithPaidBillsError";
import { SalesCannotGetInvoiceEMECeFError } from "@/features/api/components/errors/SalesCannotGetInvoiceEMECeFError";
import { SalesCannotValidateInvoiceEMECeFError } from "@/features/api/components/errors/SalesCannotValidateInvoiceEMECeFError";
import { ErrorDefinition } from "@/features/api/domain/ErrorDefinition";

import { CSVExportError } from "../components/errors/CSVExportError";
import { OrderNotFoundForClose } from "../components/errors/OrderNotFoundForClose";
import { SalesCannotEditCanceledSaleError } from "../components/errors/SalesCannotEditCanceledSaleError";
import { SalesCannotEditClosedSaleError } from "../components/errors/SalesCannotEditClosedSaleError";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const errors: Record<string, ErrorDefinition<any>> = {
  //Financing
  "financing:invalidMCFTokenProvided": {
    messageComponent: FinancingInvalidMCFTokenProvidedError,
    modalSize: "md",
    title: "Echec authentification e-MECeF",
  },

  // Order Preparation
  "orderPreparation:orderNotFoundForClose": {
    messageComponent: OrderNotFoundForClose,
    title: "Impossible de clôturer cette préparation de commande",
  },

  // Orders
  "orders:CSVExport": {
    messageComponent: CSVExportError,
    title: "Export du CSV",
  },
  // Organizations
  "organizations:archiveUnclosedBillsOrSales": {
    messageComponent: OrganizationsArchiveUnclosedBillOrSalesError,
    title: "Archivage impossible",
  },
  // Persons
  "persons:archiveUnclosedBillsOrSales": {
    messageComponent: PersonsArchiveUnclosedBillOrSalesError,
    title: "Archivage impossible",
  },
  // Roles
  "roles:cannotDeleteRoleWithUsers": {
    messageComponent: RolesCannotDeleteRoleWithUsersError,
    modalSize: "md",
    title: "Suppression impossible",
  },
  "sales:cannotCloseEmptySale": {
    buttonLabel: "Reprendre",
    messageComponent: SalesCannotCloseEmptySaleError,
    modalSize: "sm",
    title: "Clôture impossible",
  },
  "sales:cannotCloseSaleFromBeninPharmacyWithoutMCFToken": {
    messageComponent: SalesCannotCloseSaleFromBeninPharmacyWithoutMCFTokenError,
    title: "Clôture impossible",
  },
  "sales:cannotCloseSaleWithInvalidPayment": {
    buttonLabel: "Reprendre les règlements",
    hasWarning: false,
    messageComponent: SalesCannotCloseSaleWithInvalidPaymentError,
    title: "Règlements à compléter",
  },
  "sales:cannotCloseSaleWithNotEnoughPaid": {
    buttonLabel: "Reprendre les règlements",
    messageComponent: SalesCannotCloseSaleWithNotEnoughPaidError,
    modalSize: "sm",
    title: "Règlements à compléter",
  },
  "sales:cannotCloseSaleWithoutPharmacyId": {
    messageComponent: SalesCannotCloseSaleWithoutPharmacyIdError,
    title: "Clôture impossible",
  },
  "sales:cannotCloseSaleWithSameContractPolicyTwice": {
    messageComponent: SalesCannotCloseSaleWithSameContractPolicyTwiceError,
    title: "Clôture impossible",
  },
  "sales:cannotCreateInvoiceEMECeF": {
    messageComponent: SalesCannotCreateInvoiceEMECeFError,
    title: "Clôture impossible",
  },
  // Sales
  "sales:cannotCreateNewVersionOfSaleWithPaidBills": {
    messageComponent: SalesCannotCreateNewVersionOfSaleWithPaidBillsError,
    title: "Annulation impossible",
  },

  "sales:cannotEditCanceledSale": {
    messageComponent: SalesCannotEditCanceledSaleError,
    title: "Clôture impossible",
  },

  "sales:cannotEditClosedSale": {
    messageComponent: SalesCannotEditClosedSaleError,
    title: "Clôture impossible",
  },

  "sales:cannotGetInvoiceEMECeF": {
    messageComponent: SalesCannotGetInvoiceEMECeFError,
    title: "Clôture impossible",
  },

  "sales:cannotValidateInvoiceEMECeF": {
    messageComponent: SalesCannotValidateInvoiceEMECeFError,
    title: "Clôture impossible",
  },

  // Users
  "users:duplicatePinCode": {
    messageComponent: DuplicateCodePinError,
    title: "Code pin déjà utilisé",
  },
};
