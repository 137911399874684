import { User } from "@firebase/auth-types";
import { createContext } from "react";

export interface UserContextValue {
  user: null | User;
}

export const UserContext = createContext<UserContextValue>({
  user: null,
});
