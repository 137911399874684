import { CreateOrUpdateForm, Form } from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";
import { WithEdit } from "./mixins/WithEdit";
import { WithSearchableGetPage } from "./mixins/WithSearchableGetPage";

export class Endpoint extends BaseEndpoint("forms") {}

const withGetAll = WithSearchableGetPage<typeof Endpoint, Form>(Endpoint);

export const FormEndpoint = WithEdit<typeof withGetAll, CreateOrUpdateForm>(
  withGetAll,
);
