const link = {
  baseStyle: {
    color: "blue.500",
    textDecoration: "underline",
  },
  variants: {
    active: (): Record<string, string> => ({
      color: "gray.700",
      textDecoration: "none",
    }),
  },
};

export default link;
