import { BaseEndpoint } from "./BaseEndpoint";
import { GetManyPharmacyProductsQueryParams } from "../dtos";

export class StockExportEndpoint extends BaseEndpoint("stock-exports") {
  create(query?: GetManyPharmacyProductsQueryParams): Promise<Blob> {
    return this.request("", {
      method: "POST",
      type: "blob",
      query,
    });
  }
  getData(query?: GetManyPharmacyProductsQueryParams): Promise<any> {
    return this.request("", { query });
  }
}
