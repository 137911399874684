export enum MovementType {
  CASH = "CASH",
  CHEQUE = "CHEQUE",
  CREDIT_CARD = "CREDIT_CARD",
  MOBILE_MONEY = "MOBILE_MONEY",
  TRANSFER = "TRANSFER",
  INSURANCE = "INSURANCE",
  CREDIT = "CREDIT",
  CREDIT_ORG = "CREDIT_ORG",
  DISBURSEMENT = "DISBURSEMENT",
  DISCOUNT = "DISCOUNT",
}
