import {
  CreateDelivery,
  Delivery,
  DeliveryPage,
  GetDeliveriesReportQuery,
  ImportedDelivery,
  SearchGetManyDeliveriesQueryParams as SearchGetManyDeliveriesQueryParameters,
  UpdateDelivery,
} from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";
import { WithEdit } from "./mixins/WithEdit";
import { WithSearchableGetPage } from "./mixins/WithSearchableGetPage";

export class Endpoint extends BaseEndpoint("deliveries") {
  async getReport(query: GetDeliveriesReportQuery): Promise<Blob> {
    return this.request("deliveries-report-csv", { query, type: "blob" });
  }
}

const withGetPage = WithSearchableGetPage<
  typeof Endpoint,
  Delivery,
  DeliveryPage,
  SearchGetManyDeliveriesQueryParameters
>(Endpoint);

export const DeliveryEndpoint = WithEdit<
  typeof withGetPage,
  Delivery,
  CreateDelivery,
  UpdateDelivery
>(withGetPage);

export class DeliveryImportEndpoint extends BaseEndpoint("delivery-imports") {
  async import(supplierId: string, file: File): Promise<ImportedDelivery> {
    const formData = new FormData();
    if (file) formData.append("bl", file);
    formData.append("supplierId", supplierId);

    return this.request("", {
      method: "POST",
      body: formData,
    });
  }
}
