import { CreateRole, Role, UpdateRole } from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";
import { WithEdit } from "./mixins/WithEdit";
import { WithGetAll } from "./mixins/WithGetAll";

export class Endpoint extends BaseEndpoint("roles") {}

const withGetAll = WithGetAll<typeof Endpoint, Role>(Endpoint);

export const RoleEndpoint = WithEdit<
  typeof withGetAll,
  Role,
  CreateRole,
  UpdateRole
>(withGetAll);
